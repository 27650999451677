export const SocialData = [
    {
        key: 1,
        name: "One",
        features: ["Codzienne relacje", "Optymalizacja wizytówki Google", "Analiza Marketingu Onlinee"],
        superfeatuers: [" ", " "]
    },
    {
        key: 2,
        name: "Simple",
        features: ["Codzienne relacje", "Optymalizacja wizytówki Google", "Analiza Marketingu Online"],
        superfeatuers: ["klucz wizualny"]
    }
];