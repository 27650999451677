import React from 'react';

const SocialMediaAdditionals = () => {
    return (
        <div className="w-full md:pt-[5rem]" id="hero">
            <div className="max-w-[1240px] mx-auto px-4">
                <h1 className="text-[#2D2D2D] text-[38px] text-center">Oferta Social Media</h1>
                <p className="text-[16px] text-center pt-2 pb-10">
                    Ceny w pakietach <span className="font-bold">ONE</span> oraz <span className="font-bold">SIMPLE</span> są poglądowe i odzwierciedlają najbardziej podstawową opcję pakietu. Każdą wycenę robimy bezpośrednio dostosowaną pod klienta. Zapraszamy do kontaktu, sporządzimy bezpłatną analizę Twojego biznesu i na tej podstawie dostosujemy pakiet dobrany do Twoich potrzeb.
                </p>
            </div>
        </div>
    )
}

export default SocialMediaAdditionals;