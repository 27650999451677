import React from 'react'
import Blog from '../../assets/blog/blog.png'
import {Link} from "react-router-dom";

const BlogComponent = () => {
    return (
        <div className="w-full pb-[150px]">
            <div className="max-w-[1240px] mx-auto">
                <div className="grid md:grid-cols-2 px-4">
                    <div className="flex flex-col justify-center">
                        <h1 className="text-[black] text-[28px]">Inspiracje i Wskazówki dla Twojego Biznesu</h1>
                        <p className="mulish text-[#2D2D2D] pb-[30px]">Nasza sekcja blogowa to miejsce, gdzie dzielimy się praktycznymi poradami, najnowszymi trendami i inspirującymi historiami z branży. Znajdziesz tutaj treści, które pomogą Ci rozwijać swoją działalność, zrozumieć nowe technologie i skutecznie działać w środowisku cyfrowym.</p>
                        <div className="flex">
                            <Link to="/article/1" offset={150}>
                                <button
                                    className='hover:scale-105 duration-300 bg-[#932F6D] text-white text-[16px] font-semibold rounded-[20px] pt-[10px] pr-[30px] pb-[10px] pl-[30px] items-center justify-center'>
                                    Czytaj dalej
                                </button>
                            </Link>
                            <Link to="/blog" offset={150}>
                                <button
                                    className='hover:scale-105 duration-300 text-[#932F6D] border-amber-100 text-[16px] font-semibold rounded-[20px] pt-[10px] pr-[30px] pb-[10px] pl-[30px] items-center justify-center'>
                                    Sprawdź inne artykuły
                                </button>
                            </Link>
                        </div>

                    </div>
                    <div className="flex justify-center items-center">
                        <img src={Blog} alt="blog" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogComponent;