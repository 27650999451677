import React from 'react'
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BlogHero from "../components/blog/BlogHero";
import BlogArticles from "../components/blog/BlogArticles";

const Blog = () => {
    return (
        <div>
            <Navbar />
            <BlogHero />
            <BlogArticles />
            <Footer />
        </div>
    )
}

export default Blog;