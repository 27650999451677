import React from "react";

import IconWeb from "../assets/Icon_web.png";
import IconShop from "../assets/Icon_shop.png";
import IconMarketing from "../assets/Icon_marketing.png";
import {FiArrowRight} from "react-icons/fi";
import {Link} from "react-router-dom";

const Offer = () => {


    return (
        <div className="w-full md:mt-0 mt-[300px] md:pb-[200px] pb-[0px]" id="oferta">
            <div className="max-w-[1240px] mx-auto flex-col justify-center pt-[20px] md:pl-0 pl-[30px] md:pr-0 pr-[30px]">
                <div
                    className="text-[38px] font-bold text-black text-center">
                    Oferta
                </div>
                <div
                    className="text-[16px] mulish font-normal text-center">
                    Dostarczamy kompleksowe rozwiązania cyfrowe, które pomagają rozwijać Twój biznes online.
                </div>
                <div className="grid md:grid-cols-3 gap-10 pt-[5rem]">
                    <div
                        className="flex flex-col justify-center text-center border-[1px] border-[#E5F4F2] pt-[40px] pb-[40px] pr-[30px] pl-[30px] bg-white rounded-[20px]">
                        <img src={IconWeb} alt="icon" className="flex mx-auto h-[83px] w-[83px]"/>
                        <h1 className="text-[26px] font-semibold py-4 text-[#2D2D2D]">Strony internetowe</h1>
                        <p className="mulish text-[16px] font-normal">Tworzymy nowoczesne i responsywne strony
                            internetowe, dostosowane do Twoich potrzeb.</p>
                        <Link to="/offer_web#hero">
                            <button
                                className='flex mx-auto hover:scale-105 duration-300 bg-[white] mt-[30px] text-[#932F6D] text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                                Zobacz więcej <FiArrowRight/>
                            </button>
                        </Link>
                    </div>
                    <div
                        className="flex flex-col justify-center text-center border-[1px] border-[#E5F4F2] pt-[40px] pb-[40px] pr-[30px] pl-[30px] bg-white rounded-[20px]">
                        <img src={IconMarketing} alt="icon" className="flex mx-auto h-[83px] w-[83px]"/>
                        <h1 className="text-[26px] font-semibold py-4 text-[#2D2D2D]">Social Media Marketing</h1>
                        <p className="mulish text-[16px] font-normal">Pomagamy budować silną obecność w mediach społecznościowych, zwiększając zaangażowanie Twojej marki.</p>
                        <Link to="/offer_social">
                            <button
                                className='flex mx-auto hover:scale-105 duration-300 bg-[white] mt-[30px] text-[#932F6D] text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                                Zobacz więcej <FiArrowRight/>
                            </button>
                        </Link>
                    </div>
                    <div
                        className="flex flex-col justify-center text-center border-[1px] border-[#E5F4F2] pt-[40px] pb-[40px] pr-[30px] pl-[30px] bg-white rounded-[20px]">
                        <img src={IconShop} alt="icon" className="flex mx-auto h-[83px] w-[83px]"/>
                        <h1 className="text-[26px] font-semibold py-4 text-[#2D2D2D]">Sklepy Internetowe</h1>
                        <p className="mulish text-[16px] font-normal">Tworzymy intuicyjne sklepy internetowe, które pomagają zwiększyć sprzedaż online. Tworzymy intuicyjne sklepy internetowe</p>
                        <Link to="/offer_ecommerce">
                            <button
                                className='flex mx-auto hover:scale-105 duration-300 bg-[white] mt-[30px] text-[#932F6D] text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                                Zobacz więcej <FiArrowRight/>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Offer