import React from 'react'
import SocialMediaHero from "../components/socialmediacomponents/SocialMediaHero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import SocialMediaAdditionals from "../components/socialmediacomponents/SocialMediaAdditionals";

const SocialOffer = () => {
    return (
        <div>
            <Navbar />
            <SocialMediaHero />
            <SocialMediaAdditionals />
            <Contact />
            <Footer />
        </div>
    )
}

export default SocialOffer;