import React from "react";
import { useParams } from "react-router-dom";
import { blogData } from "../json/BlogData";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import NoPage from "./NoPage"; // Import danych

const ArticlePage = () => {
    const { id } = useParams();
    const article = blogData.find((item) => item.id === parseInt(id));
    if (!article) {
        return <NoPage title="Nie znaleziono artykułu!"/>;
    }

    return (
        <div>
            <Navbar />
            <div className="max-w-[1240px] mx-auto py-10 px-4">
                <div className="grid md:grid-cols-2 gap-4">
                    <div className="">
                        <p className="text-[12px]">{article.date} {article.author}</p>
                        <h1 className="text-[24px]">{article.title}</h1>
                        <p className="mulish">{article.desc}</p>
                    </div>
                    <div
                        className="bg-cover bg-center w-full h-[300px] md:h-[500px] py-3 md:py-0"
                        style={{backgroundImage: `url(${article.img})`}}
                    ></div>
                </div>
                <div className="grid md:grid-cols-2 py-2 gap-4">
                    <div>
                        <h1 className="text-[24px]">{article.contentTitle}</h1>
                        <p className="mulish">{article.content}</p>
                    </div>
                    <div>
                        <h1 className="text-[24px]">{article.secondContentTitle}</h1>
                        <p className="mulish">{article.secondContent}</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ArticlePage;
