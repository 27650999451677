import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import ScrollLink from "../hooks/ScrollLink";
import { FaEye } from "react-icons/fa";

const Popup = ({ isOpen, onClose, project }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        if (project?.images?.length > 0) {
            setSelectedImage(project.images[0]);
        } else {
            setSelectedImage(null);
        }
    }, [project]);

    if (!isOpen) return null;

    const images = project?.images || [];

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]"
        >
            <div
                className="bg-white rounded-lg shadow-lg p-4 sm:p-6 max-w-[90%] sm:max-w-4xl w-full max-h-[90%] overflow-auto relative">

                <button
                    className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 text-xl"
                    onClick={onClose}
                >
                    &times;
                </button>

                {project && (
                    <>


                        {selectedImage ? (
                            <div className="flex justify-center mb-4">
                                <img
                                    src={selectedImage}
                                    alt="Selected"
                                    className="rounded-lg max-h-96 w-auto object-cover"
                                />
                            </div>
                        ) : (
                            <p className="text-center text-gray-500">Brak dostępnych zdjęć</p>
                        )}

                        {images.length > 0 && (
                            <div className="flex flex-wrap gap-4 justify-center">
                                {images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image}
                                        alt={`Thumbnail ${index + 1}`}
                                        onClick={() => setSelectedImage(image)}
                                        className={`cursor-pointer h-20 w-auto rounded-lg object-cover border-2 ${
                                            selectedImage === image
                                                ? "border-[#932F6D]"
                                                : "border-transparent"
                                        } hover:scale-105 transition-transform duration-200`}
                                    />
                                ))}
                            </div>
                        )}
                        <h2 className="text-2xl font-bold mb-4 pt-[20px] text-[#2D2D2D]">
                            {project.subtitle}
                        </h2>
                        <p className="mulish text-[#2D2D2D] mt-4">{project.desc}</p>
                        <div className="flex items-center pt-[50px] gap-4 justify-center md:justify-normal">
                            <ScrollLink to="#contact" offset={150}>
                                <button
                                    onClick={onClose}
                                    className='hover:scale-105 duration-300 text-[#932F6D] text-[16px] font-semibold rounded-[20px] pt-[5px] pr-[5px] pb-[5px] pl-[5px] items-center justify-center'>
                                    Napisz do nas!
                                </button>
                            </ScrollLink>
                            <Link to={project.url} target="_blank">
                                <button
                                    onClick={onClose}
                                    className='hover:scale-105 duration-300 text-[white] bg-[#932F6D] border-amber-100 text-[16px] font-semibold rounded-[10px] pt-[10px] pr-[15px] pb-[10px] pl-[15px] items-center justify-center'>
                                    <FaEye/>
                                </button>
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Popup;
