import React from 'react';
import IconWeb from "../../assets/Icon_web.png";
import {FiArrowRight} from "react-icons/fi";
import ScrollLink from "../../hooks/ScrollLink";

const WebHero = () => {


    return (
        <div className="w-full md:pt-[5rem] py-16" id="hero">
            <div className="max-w-[1240px] mx-auto">
                <h1 className="text-[#2D2D2D] text-[38px] text-center">Pakiety cenowe</h1>
                <p className="text-[16px] text-center pt-2 pb-10 px-4">Ceny są poglądowe i w rzeczywistości mogą nie odzwierciedlać realnych cen. Każdy projekt dostosowujemy indywidualnie pod klienta</p>
                <div className="grid md:grid-cols-3 gap-2 md:mx-0 mx-2 pb-10">
                    <div
                        className="flex flex-col justify-center text-center border-[1px] border-[#E5F4F2] pt-[10px] pb-[40px] pr-[30px] pl-[30px] bg-white rounded-[20px]">
                        <img src={IconWeb} alt="icon" className="flex mx-auto h-[83px] w-[83px]"/>
                        <h1 className="text-[22px] font-semibold pt-4 text-[#2D2D2D]">START</h1>
                        <h1 className="text-[26px] font-semibold text-[#2D2D2D]">1000 <sup>zł</sup></h1>
                        <p className="mulish text-[16px] pt-4 font-normal">Idealna dla małych firm na start. Strona typu one-page, maksymalnie do 2 podstron.</p>
                        <p className="text-[16px] pt-4 font-normal" data-tooltip="Projekt graficzny, dostosowany do Twoich potrzeb.">✔ Projekt wizualny</p>

                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="W cenie usługi wliczony jest zakup domeny">✔ Zakup domeny</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="W cenie usługi wliczony jest stworzenie dokumentów.">✔ Polityka prywatności</p>
                        <p className="text-[16px] pt-4 font-normal" data-tooltip="Twój własny Certyfikat SSL">✔ Certyfikat
                            SSL</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Twoja strona dopasuje się do telefonów komórkowych oraz tabletów">✔ Responsywność</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Formularz kontaktowy, dzięki któremu Twoi klienci będą mogli w prosty sposób się z Tobą skontaktować">✔ Formularz
                            kontaktowy</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Pełna zgodność zasadami Google dot. tworzenia stron internetowych oraz Google AdWords">✔ Zgodna
                            z Google</p>
                        <ScrollLink
                            to="#contact"
                            offset={150}
                        >
                            <button
                                className='flex mx-auto hover:scale-105 duration-300 bg-[white] mt-[30px] text-[#932F6D] text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                                Zamów <FiArrowRight/>
                            </button>
                        </ScrollLink>
                    </div>
                    <div
                        className="flex flex-col justify-center text-center border-[1px] border-[#E5F4F2] pt-[40px] pb-[40px] pr-[30px] pl-[30px] bg-[#431532] rounded-[20px] text-white">
                        <img src={IconWeb} alt="icon" className="flex mx-auto h-[83px] w-[83px]"/>
                        <h1 className="text-[22px] font-semibold pt-4">SIMPLE</h1>
                        <h1 className="text-[26px] font-semibold">2500 <sup>zł</sup></h1>
                        <p className="mulish text-[16px] pt-4 font-normal">Strona zawiera wszystko co poprzedni pakiet, oraz dodatkowo:</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Po oddaniu przez nas projektu, wspieramy Cię przez 3 miesiące, odpowiadamy na pytania, tworzymy kopie zapasowe i pozwalamy Ci w swobodny sposób oswoić się ze swoją nową stroną internetową">Wsparcie naszych ekspertów</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Integracja z Google Maps - Twoi klienci w łatwy sposób sprawdzą gdzie znajduje się Twoja siedziba!">✔ Mapa z API Google</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Wsparcie w Google Analitics">✔ Google Analitics</p>
                        <p className="text-[16px] pt-4 font-normal" data-tooltip="Facebook Piksel Meta">✔ Piksel Meta</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Więcej formularzy na Twojej stronie np. newsletter">✔ Dodatkowe formularze</p>
                        <ScrollLink
                            to="#contact"
                            offset={150}
                        >
                            <button
                                className='flex mx-auto hover:scale-105 duration-300 mt-[30px] text-[white] text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                                Zamów <FiArrowRight/>
                            </button>
                        </ScrollLink>
                    </div>
                    <div
                        className="flex flex-col justify-center text-center border-[1px] border-[#E5F4F2] pt-[10px] pb-[40px] pr-[30px] pl-[30px] bg-white rounded-[20px]">
                        <img src={IconWeb} alt="icon" className="flex mx-auto h-[83px] w-[83px]"/>
                        <h1 className="text-[22px] font-semibold pt-4 text-[#2D2D2D]">FULL</h1>
                        <h1 className="text-[26px] font-semibold text-[#2D2D2D]">5000 <sup>zł</sup></h1>
                        <p className="mulish text-[16px] pt-4 font-normal">Strona zawiera wszystko co poprzedni pakiet, oraz dodatkowo:</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Projekt graficzny, dostosowany do Twoich potrzeb, na wyłączność">✔ Indywidualny szablon witryny</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="System obsługi blogów">✔ Blog</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Po oddaniu przez nas projektu, wspieramy Cię przez 3 miesiące, odpowiadamy na pytania, tworzymy kopie zapasowe i pozwalamy Ci w swobodny sposób oswoić się ze swoją nową stroną internetową">✔ Wsparcie
                            naszych ekspertów</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Integracja z Google Maps - Twoi klienci w łatwy sposób sprawdzą gdzie znajduje się Twoja siedziba!">✔ Mapa
                            z API Google</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Wsparcie w Google Analitics">✔ Google Analitics</p>
                        <p className="text-[16px] pt-4 font-normal" data-tooltip="Facebook Piksel Meta">✔ Piksel Meta</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Więcej formularzy na Twojej stronie np. newsletter">✔ Dodatkowe formularze</p>
                        <ScrollLink
                            to="#contact"
                            offset={150}
                        >
                            <button
                                className='flex mx-auto hover:scale-105 duration-300 mt-[30px] text-[#932F6D] text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                            Zamów <FiArrowRight/>
                            </button>
                        </ScrollLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebHero;