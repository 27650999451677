import krok_min from '../assets/krok.png'
import gospodarna from '../assets/gospodarna.png'
import gospodarna_image1 from '../assets/gospodarna_image1.png'
import gospodarna_image2 from '../assets/gospodarna_image2.png'
import gospodarna_image3 from '../assets/gospodarna_image3.png'
import gospodarna_image4 from '../assets/gospodarna_image4.png'
import sudety from '../assets/sudety.png'
import sudety_image1 from '../assets/sudety_image1.png'
import sudety_image2 from '../assets/sudety_image2.png'
import sudety_image3 from '../assets/sudety_image3.png'
import sudety_image4 from '../assets/sudety_image4.png'
import krok_image1 from '../assets/krok_image1.png'
import krok_image2 from '../assets/krok_image2.png'
import krok_image3 from '../assets/krok_image3.png'
import krok_image4 from '../assets/krok_image4.png'

export const data = [
  {
    key: 1,
    name: "Krok Gwiazd",
    subtitle: "KrokGwiazd.PL - Styl i wygoda w jednym miejscu!",
    desc: "Projekt sklepu internetowego 'Krok Gwiazd' był dla nas szczególnym wyzwaniem i jednocześnie okazją do stworzenia kompleksowej platformy e-commerce, która łączy estetykę z funkcjonalnością. Strona została zaprojektowana i wdrożona z myślą o sprzedaży obuwia renomowanych marek, takich jak Nike czy Puma, co wymagało stworzenia atrakcyjnej wizualnie witryny, odzwierciedlającej dynamiczny charakter branży modowej.\n" +
        "\n" +
        "Projektując sklep, skupiłem się na intuicyjnym układzie nawigacji, który ułatwia użytkownikom przeglądanie produktów oraz realizację zakupów. Platforma została zbudowana w oparciu o nowoczesne technologie, zapewniające szybkość działania i bezpieczeństwo transakcji. W procesie realizacji zadbałem o zoptymalizowanie strony pod kątem SEO, aby zwiększyć widoczność sklepu w wyszukiwarkach i przyciągnąć większy ruch organiczny.\n" +
        "\n" +
        "Ważnym elementem projektu była również integracja z systemami płatności online oraz modułami zarządzania stanami magazynowymi, co umożliwia sprawne prowadzenie sprzedaży i aktualizowanie oferty. Całość została zoptymalizowana pod kątem urządzeń mobilnych, co jest kluczowe w kontekście współczesnych trendów zakupowych.\n" +
        "\n" +
        "Oprócz technicznych aspektów, zająłem się również stworzeniem treści i elementów wizualnych, które budują spójny wizerunek marki 'Krok Gwiazd'. Rezultatem jest nowoczesny sklep internetowy, który odpowiada potrzebom zarówno właściciela biznesu, jak i jego klientów.\n" +
        "\n" +
        "Ten projekt to przykład kompleksowego podejścia do tworzenia platform e-commerce, które nie tylko działają, ale również angażują wizualnie i funkcjonalnie.",
    url: "https://krokgwiazd.pl",
    img: krok_min,
    images: [
      krok_image1,
      krok_image2,
      krok_image3,
      krok_image4,
    ],
  },
  {
    key: 2,
    name: "Gospodarna Polska",
    subtitle: "GospodarnaPolska.PL - Razem budujemy zrównoważoną i gospodarczą przyszłość Polski!",
    desc: "Zaprojektowaliśmy i wdrożyliśmy stronę internetową dla organizacji 'Gospodarna Polska', która promuje dynamiczny rozwój społeczny i gospodarczy kraju. Strona została stworzona z myślą o przejrzystości i łatwości użytkowania, umożliwiając odwiedzającym szybkie zapoznanie się z celami organizacji, jej aktualnościami oraz kluczowymi projektami.\n\nW trakcie realizacji projektu zadbaliśmy o pełną responsywność strony, co gwarantuje komfortowe korzystanie zarówno na komputerach, jak i urządzeniach mobilnych. Strona została zoptymalizowana pod kątem SEO, aby zapewnić lepszą widoczność w wyszukiwarkach i dotrzeć do szerszego grona odbiorców.\n\nDzięki zastosowanym technologiom i dbałości o detale 'Gospodarna Polska' zyskała profesjonalny wizerunek online, który wspiera jej działania w budowaniu społeczeństwa obywatelskiego, promowaniu decentralizacji oraz wspieraniu rozwoju infrastruktury i rolnictwa.",
    url: "https://gospodarnapolska.pl",
    img: gospodarna,
    images: [gospodarna_image1, gospodarna_image2, gospodarna_image3, gospodarna_image4],
  },

  {
    key: 3,
    name: "Sudety Drewno",
    subtitle: "KrokGwiazd.PL - Styl i wygoda w jednym miejscu!",
    desc: "Zrealizowaliśmy projekt sklepu internetowego dla 'Sudety Drewno', producenta drewnianych uli i akcesoriów pszczelarskich. Sklep został zaprojektowany z myślą o funkcjonalności i estetyce, oferując użytkownikom intuicyjny sposób przeglądania i zakupu produktów. Oferta obejmuje szeroką gamę wyrobów drewnianych, takich jak ule, dennice, daszki oraz akcesoria do pszczelarstwa.\n\nW projekcie zadbaliśmy o responsywność sklepu, dzięki czemu klienci mogą dokonywać zakupów zarówno na komputerach, jak i urządzeniach mobilnych. Sklep został zintegrowany z systemami płatności online, co ułatwia realizację zamówień. Optymalizacja SEO pozwala na zwiększenie widoczności sklepu w wyszukiwarkach, przyciągając większą liczbę klientów. Dodatkowo wdrożyliśmy system zarządzania treścią, co umożliwia łatwe aktualizowanie oferty i wprowadzanie nowych produktów.",
    url: "https://sudetydrewno.com.pl/",
    img: sudety,
    images: [sudety_image1, sudety_image2, sudety_image3, sudety_image4],
  },
]