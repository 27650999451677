import React, { useRef, useState } from "react";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { TbWriting } from "react-icons/tb";
import emailjs from "@emailjs/browser";
import {Link} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Contact = ({ title = "Napisz do Nas!", subtitle = "Z przyjemnością odpowiemy na Twoje zapytania i doradzimy najlepsze rozwiązania dla Twojego biznesu." }) => {
    const formRef = useRef(null);

    const {REACT_APP_SERVICE_ID,REACT_APP_TEMPLATE_ID_MAIN, REACT_APP_OPTIONS} = process.env;
    const recaptchaRef = useRef(null);

    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;


    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        message: ''
    });


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = await recaptchaRef.current.executeAsync();

        if(!token) {
            toast.error("Weryfikacja reCAPTCHA nie powiodła się.");
            return;
        }


        const fieldsFilled = formData.email && formData.fullName && formData.message;
        if (!fieldsFilled) {
            toast.warn("Wypełnij wszystkie pola formularza!");
            return;
        }

        try {
            await emailjs.sendForm(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID_MAIN, formRef.current, REACT_APP_OPTIONS, token);
            toast.success("Sukces! Przesłano formularz. Niedługo się z Tobą skontaktujemy!");

            setFormData({
                fullName: '',
                email: '',
                message: ''
            });

        } catch (e) {
            toast.error('Wystąpił błąd podczas wysyłania wiadomości.');
            console.error('Failed...', e);
        }
    };

    return (
        <div className="w-full mb-[200px] sm:mt-[0px] md:mt-[0px] mt-[0px]" id="contact">
            <div
                className="max-w-[1240px] mx-auto rounded-xl bg-[white] pt-[20px] pb-[20px] pl-[20px] pr-[20px] md:pl-[40px]"
            >
                <h1 className="text-[28px] md:text-[48px] font-bold">{title}</h1>
                <p className="text-[16px] mulish font-normal text-[#2D2D2D]">
                    {subtitle}
                </p>

                <form ref={formRef} onSubmit={handleSubmit} className="grid md:grid-cols-2 pt-[25px]">
                    <div className="md:w-[80%]">
                        <div className="flex text-lg gap-2">
                            <div className="w-1/2 flex justify-normal">
                                <div className="flex gap-2">
                                    <FiPhoneCall size={20} className="mt-[2px]" color="#932F6D" />
                                    <span className="text-[15px] md:text-[18px]"><Link to="tel:+48724598509">+48 724 598 509</Link></span>
                                </div>
                            </div>
                            <div className="w-1/2 flex md:justify-end">
                                <div className="flex justify-end gap-2">
                                    <MdOutlineMarkEmailRead size={24} color="#932F6D" />
                                    <span className="text-[15px] md:text-[18px]"><Link to="mailto:bok@hkcode.pl">bok@hkcode.pl</Link></span>
                                </div>
                            </div>
                        </div>
                        <div className="w-[100%]">
                            <div className="relative py-6">
                                <FaRegUser size={16} className="fas fa-user absolute left-3 top-1/2 transform -translate-y-1/2" color="#932F6D" />
                                <input
                                    type="text"
                                    id="fullName"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#932F6D]"
                                    placeholder="Imię i Nazwisko"
                                    required
                                />
                            </div>
                        </div>
                        <div className="w-[100%]">
                            <div className="relative">
                                <MdOutlineMarkEmailRead size={18} className="fas fa-user absolute left-3 top-1/2 transform -translate-y-1/2" color="#932F6D" />
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#932F6D]"
                                    placeholder="Email"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-[80%] pt-6 md:pt-0">
                        <div className="relative">
                            <TbWriting size={18}
                                       className="fas fa-pencil-alt absolute left-3 top-[22px] transform -translate-y-1/2"
                                       color="#932F6D"/>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#932F6D] h-40 resize-none"
                                placeholder="Twoja wiadomość"
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full mt-6 bg-[#932F6D] text-white font-bold py-3 px-6 rounded-lg hover:bg-black transition-colors"
                        >
                            Wyślij
                        </button>
                    </div>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={recaptchaSiteKey}
                        size="invisible"
                        className=""
                    />
                </form>
                <ToastContainer position="top-right" autoClose={5000} />
            </div>
        </div>
    );
};

export default Contact;
