import React from 'react';

const WebAdditionals = () => {
    return (
        <div className="w-full md:pt-[5rem]">
            <div className="max-w-[1240px] mx-auto">
                <h1 className="text-[#2D2D2D] text-[38px] text-center">USŁUGI DODATKOWE</h1>
                <div className="w-full h-[2px] bg-[#932F6D]"></div>
                <div className="grid md:grid-cols-2 pt-[40px] pb-[40px] mx-8">
                    <div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Instalacja zaawansowanych statystyk Google
                                    Analytics</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">gratis</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Optymalizacja pod kątem SEO</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">gratis</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Moduł Live Chat</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">100 zł</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Utworzenie profilu firmowego Facebook</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">100 zł</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Integracja strony z Facebook</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">100 zł</p>
                            </div>
                        </div>


                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Dodatkowa podstrona</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">250 zł</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Kampania Google Ads</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">20% budżetu</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Aktualizacja strony klienta</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">500 zł</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Odnowienie domeny</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">200 zł</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Rejestracja domeny</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">120 zł</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Odnowienie hostingu</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">330 zł</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Utworzenie profesjonalnego tekstu</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">50 zł</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Dodatkowa wersja językowa Twojej
                                    strony</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">1200 zł</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Rejestracja w Mapach Google (Google Moja
                                    Firma)</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">200 zł</p>
                            </div>
                        </div>
                        <div className="flex py-1">
                            <div className="w-[90%]">
                                <p className="mulish text-[14px] font-normal">Logotyp firmy</p>
                            </div>
                            <div className="w-[10%]">
                                <p className="mulish text-[14px] font-bold">500 zł</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WebAdditionals;