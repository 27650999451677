
import React from "react";
import Stars from "../assets/stars.png"


const Testimonials = () => {


    return (
        <div className="w-full bg-[#932F6D] pb-[64px] md:mt-0 mt-[32px]" id="opinie">
            <div className="max-w-[1240px] mx-auto md:pl-0 pl-[30px] md:pr-0 pr-[30px]">
                <div

                    className="text-[38px] font-bold text-white text-center pt-[50px] md:pt-[64px]">
                    Satysfakcja klienta!
                </div>
                <div
                    className="text-[16px] mulish font-normal text-center text-[#DADADA]">
                    Sprawdź co sądzą o Nas klienci z którymi współpracowałem
                </div>
                <div className="grid md:grid-cols-3 gap-3 pt-[50px]">
                    <div
                        className="flex flex-col justify-center text-center border-[1px] border-[#E5F4F2] pt-[40px] pb-[40px] pr-[30px] pl-[30px] bg-white rounded-[20px]">

                        <h1 className="text-[21px] font-semibold pt-4 text-[#111827]">Aleksandra Domańska</h1>
                        {/*<p className="text-[#374151] pb-4">ATWDevelopment.pl</p>*/}
                        <p className="mulish text-[16px] font-normal">Zespół w pełni odmienił nasze podejście do social media. Dzięki ich strategii i regularnym publikacjom nasza marka stała się bardziej rozpoznawalna, a liczba klientów znacząco wzrosła. Kreatywne pomysły, profesjonalne grafiki i skuteczne kampanie reklamowe – to wszystko sprawiło, że widzimy realne efekty ich pracy. Gorąco polecam współpracę!</p>
                        <img src={Stars} alt="stars" className="pt-4"/>
                    </div>
                    <div
                        className="flex flex-col justify-center text-center border-[1px] border-[#E5F4F2] pt-[40px] pb-[40px] pr-[30px] pl-[30px] bg-white rounded-[20px]">

                        <h1 className="text-[21px] font-semibold pt-4 text-[#111827]">Katarzyna Mazur</h1>
                        {/*<p className="text-[#374151] pb-4">Stylowa Szafa</p>*/}
                        <p className="mulish text-[16px] font-normal">Zespół wykazał się pełnym profesjonalizmem podczas tworzenia mojego sklepu internetowego. Sklep działa bez zarzutu, a obsługa jest intuicyjna nawet dla osób bez doświadczenia technicznego. Dodatkowo świetny kontakt i szybkie wsparcie techniczne. Polecam z całego serca!</p>
                        <img src={Stars} alt="stars" className="pt-4"/>
                    </div>
                    <div
                        className="flex flex-col justify-center text-center border-[1px] border-[#E5F4F2] pt-[40px] pb-[40px] pr-[30px] pl-[30px] bg-white rounded-[20px]">
                        <h1 className="text-[21px] font-semibold pt-4 text-[#111827]">Tomasz Wiśniewski</h1>
                        {/*<p className="text-[#374151] pb-4">UX Designer @Brello</p>*/}
                        <p className="mulish text-[16px] font-normal">Zdecydowaliśmy się na dedykowany sklep internetowy i to była świetna decyzja! Sklep został zaprojektowany od podstaw zgodnie z naszymi potrzebami. Integracja z systemem ERP oraz wielowalutowość to strzał w dziesiątkę. Dzięki zespołowi sprzedaż wzrosła o 40%! Dziękujemy za zaangażowanie i profesjonalizm!</p>
                        <img src={Stars} alt="stars" className="pt-4"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials