import React from 'react';
import { Link } from 'react-router-dom';

const ScrollLink = ({ to, children, className, offset = 0, options = { behavior: 'smooth' } }) => {
    const handleClick = (event) => {
        const target = to.startsWith('#') ? document.querySelector(to) : null;

        if (target) {
            event.preventDefault();

            const elementPosition = target.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: options.behavior || 'smooth',
            });

            window.history.pushState(null, '', to);
        }
    };

    return (
        <Link to={to} className={className} onClick={handleClick}>
            {children}
        </Link>
    );
};

export default ScrollLink;
