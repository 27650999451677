import React from 'react'
import {blogData} from '../../json/BlogData'
import {Link} from "react-router-dom";
const BlogArticles = () => {


    return (
        <div className="w-full">
            <div className="max-w-[1240px] mx-auto px-4 pt-[5rem] pb-[5rem]">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {blogData.map((blog, index) => (
                        <Link to={`/article/${blog.id}`} key={index}>
                            <div
                                className="flex flex-col rounded-t-[25px] bg-white hover:scale-105 duration-300 h-[250px] min-h-[250px]">
                                <div className="flex flex-col flex-1 p-4">
                                    <h1 className="text-[24px] pt-[10px]">{blog.title}</h1>
                                    <p className="mulish overflow-hidden text-ellipsis whitespace-nowrap flex-grow">
                                        {blog.desc.length > 100 ? blog.desc.slice(0, 100) + '...' : blog.desc}
                                    </p>
                                    <div className="mt-auto flex justify-between items-center">
                                        <p className="mulish text-[12px]">
                                            {blog.date} {blog.author}
                                        </p>
                                        <Link to={`/article/${blog.id}`}>
                                            <button
                                                className="hover:scale-105 duration-300 text-[#932F6D] border-amber-100 text-[14px] font-semibold rounded-[20px]">
                                                Czytaj dalej
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BlogArticles;