import React from "react";
import Visuals from '../assets/visuals.png'
import ScrollLink from "../hooks/ScrollLink";


const Hero = () => {


    return (
        <div className="w-full md:py-[5rem] py-14" id="o-nas">
            <div className="max-w-[1240px] mx-auto px-[5px]">
                <div className="grid md:grid-cols-2 gap-8">
                    <div className="text-center md:text-left animate-slide-from-left">
                        <h1 className="text-[57px] md:text-[67px] font-bold text-[#2D2D2D] py-4">HKCODE.PL</h1>
                        <p className="mulish font-normal text-[18px] md:text-[21px] text-[#2D2D2D]">Oferujemy nowoczesne rozwiązania,
                            które pomogą Ci wyróżnić się online i zrealizować cele biznesowe. Od stron internetowych po
                            marketing w social media i sklepy internetowe – jesteśmy tu, aby wspierać Twój sukces.</p>
                        <div className="flex items-center pt-[50px] gap-8 justify-center md:justify-normal">
                            <ScrollLink to="#contact" offset={150}>
                                <button
                                    className='hover:scale-105 duration-300 bg-[#932F6D] text-white text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                                    Napisz do nas!
                                </button>
                            </ScrollLink>
                            <ScrollLink to="#oferta" offset={150}>
                                <button
                                    className='hover:scale-105 duration-300 bg-[#F8F9FF] text-[#932F6D] border-amber-100 text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                                    Sprawdź ofertę
                                </button>
                            </ScrollLink>
                        </div>
                    </div>


                    <div className="flex justify-center md:justify-end animate-slide-from-right">
                        <div>
                            <img src={Visuals} alt="visuals" className="flex justify-end"/>
                        </div>
                    </div>
                </div>

                {/*<motion.img*/}
                {/*    className="pt-[60px] animate-slide-from-bottom"*/}
                {/*    src={Logos}*/}
                {/*    style={{*/}
                {/*        scale: isMobile ? 1 : scaleLogos,*/}
                {/*        opacity: isMobile ? 1 : opacityLogos,*/}
                {/*    }}*/}
                {/*/>*/}
            </div>
        </div>
    )
}

export default Hero