import React from 'react'
import ScrollLink from "../../hooks/ScrollLink";
import {FiArrowRight} from "react-icons/fi";
import {Navigation, Pagination} from "swiper/modules";
import {SocialData} from "../../json/SocialMediaData";
import {Swiper, SwiperSlide} from "swiper/react";

const SocialMediaHero = () => {
    return (
        <div className="w-full md:pt-[5rem] py-16" id="hero">
            <div className="max-w-[1240px] mx-auto px-4">
                <h1 className="text-[#2D2D2D] text-[38px] text-center">Pakiety cenowe</h1>
                <p className="text-[16px] text-center pt-2 pb-10">Ceny są poglądowe i w rzeczywistości mogą nie
                    odzwierciedlać realnych cen. Każdy projekt dostosowujemy indywidualnie pod klienta</p>
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#932F6D",
                        "--swiper-pagination-bullet-inactive-color": "#E5E5E5",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "12px",
                        "--swiper-pagination-bullet-horizontal-gap": "6px",

                        "--swiper-navigation-color": "#932F6D",
                        "--swiper-navigation-bullet-inactive-color": "#FFFFFF",
                        "--swiper-navigation-bullet-inactive-opacity": "1",
                        "--swiper-navigation-bullet-size": "12px",
                        "--swiper-navigation-bullet-horizontal-gap": "6px"
                    }}
                    slidesPerView={3}
                    spaceBetween={30}
                    loop={true}
                    breakpoints={{
                        320: {slidesPerView: 1, spaceBetween: 30},
                        480: {slidesPerView: 1, spaceBetween: 30},
                        768: {slidesPerView: 1, spaceBetween: 30},
                        1024: {slidesPerView: 1, spaceBetween: 30},
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        enabled: true,
                        delay: 2*1000
                    }}

                    navigation
                    modules={[Pagination, Navigation]}
                >
                    {
                        SocialData.map((item, index) => (
                            <SwiperSlide
                                className="flex flex-col border-[1px] border-[#E5F4F2] bg-white rounded-[20px] pb-4">
                                <div key={item.key}>
                                    {/*<img src={item.img} alt="project-background" className="w-[100%] pt-0"/>*/}
                                    <h1 className="text-[28px] px-4 font-semibold pt-4 text-[#2D2D2D]">{item.name}</h1>
                                    <ul>
                                        <li
                                        key={index}
                                        className="py-2"
                                        >
                                            {item.features.map((feature) => (
                                                <div>
                                                    {feature}
                                                </div>
                                            ))}
                                        </li>
                                    </ul>
                                    <ul className="pt-[15px]">
                                        <li
                                            key={index}
                                            className="font-bold py-2">
                                            {item.superfeatuers.map((feature) => (
                                                <div>
                                                    {feature}
                                                </div>
                                            ))}
                                        </li>
                                    </ul>
                                    <ScrollLink
                                        to="#contact"
                                        offset={150}
                                    >
                                        <button
                                            className='flex mx-auto hover:scale-105 duration-300 bg-[white] mt-[30px] text-[#932F6D] text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                                            Zamów <FiArrowRight/>
                                        </button>
                                    </ScrollLink>
                                </div>

                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    )
}
export default SocialMediaHero;