import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper/modules";

import { data } from "../json/Data.js";
import Popup from "../popups/Popup";

const Portfolio = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  const openPopup = (project) => {
    setSelectedProject(project);
  };

  const closePopup = () => {
    setSelectedProject(null);
  };

  return (
    <div className="w-full md:pt-[50px] pt-[100px]" id="portfolio">
      <div className="max-w-[1240px] mx-auto flex-col justify-center pt-[20px] md:pl-0 pl-[30px] md:pr-0 pr-[30px]">
        <div className="text-[38px] font-bold text-black text-center">
          Nasze prace
        </div>
        <div className="text-[16px] mulish font-normal text-center pb-[30px]">
          Poznaj Nasze portfolio i zobacz ostatnie i najlepsze projekty, które
          zrobiliśmy.
        </div>
        {/*todo: open popup on click swiper*/}
        <div>
          <Swiper
            style={{
              "--swiper-pagination-color": "#932F6D",
              "--swiper-pagination-bullet-inactive-color": "#E5E5E5",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": "12px",
              "--swiper-pagination-bullet-horizontal-gap": "6px",

              "--swiper-navigation-color": "#932F6D",
              "--swiper-navigation-bullet-inactive-color": "#FFFFFF",
              "--swiper-navigation-bullet-inactive-opacity": "1",
              "--swiper-navigation-bullet-size": "12px",
              "--swiper-navigation-bullet-horizontal-gap": "6px",
              paddingBottom: '150px',
            }}
            slidesPerView={3}
            spaceBetween={30}
            loop={true}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 30 },
              480: { slidesPerView: 1, spaceBetween: 30 },
              768: { slidesPerView: 3, spaceBetween: 30 },
              1024: { slidesPerView: 3, spaceBetween: 30 },
            }}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              enabled: true,
              delay: 2 * 1000,
            }}
            navigation
            modules={[Pagination, Navigation]}
          >
            {data.map((item) => (
              <SwiperSlide className="flex flex-col border-[1px] border-[#E5F4F2] bg-white rounded-[20px] pb-4">
                <div key={item.id}>
                  <img
                    src={item.img}
                    alt="project-background"
                    className="w-[100%] pt-0"
                  />
                  <h1 className="text-[28px] px-4 font-semibold pt-4 text-[#2D2D2D]">
                    {item.name}
                  </h1>
                  <p className="text-[21px] font-normal mulish text-[#2D2D2D] px-4 pb-4">
                    {item.desc.length > 50
                      ? item.desc.slice(0, 50) + "..."
                      : item.name}
                  </p>
                  <button
                    onClick={() => openPopup(item)}
                    className="hover:scale-105 duration-300 bg-[white] text-[#932F6D] border-amber-100 text-[16px] font-semibold rounded-[20px] pb-[30px] items-center justify-center"
                  >
                    Sprawdź więcej
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Popup
          isOpen={!!selectedProject}
          onClose={closePopup}
          project={selectedProject}
        />
      </div>
    </div>
  );
};

export default Portfolio;
