import React from 'react'

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ECommerceHero from "../components/ecommercecomponents/ECommerceHero";
import Contact from "../components/Contact";
import ECommerceMore from "../components/ecommercecomponents/ECommerceMore";


const ECommerceOffer = () => {
    return (
        <div>
            <Navbar />
            <ECommerceHero />
            <ECommerceMore />
            <Contact title="Skontaktuj się!" subtitle="Nasza oferta obejmuje dwa warianty sklepów internetowych, które mogą sprostać różnorodnym oczekiwaniom i wymaganiom Twojego biznesu. Wybierz rozwiązanie, które najlepiej odpowiada Twoim potrzebom"/>
            <Footer />
        </div>
    )
}

export default ECommerceOffer;