import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Offer from "../components/Offer";
import Testimonials from "../components/Testimonials";
import Portfolio from "../components/Portfolio";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import BlogComponent from "../components/blog/BlogComponent";
import React from "react";



export const Home = () => {
    return (
        <div>
            <Navbar />
            <Hero />
            <Offer />
            <Testimonials />
            <Portfolio />
            <BlogComponent />
            <Contact />
            <Footer />
        </div>
    )
}

export default Home;
