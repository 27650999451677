import React from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import page404 from "../assets/404.png"
import {Link} from "react-router-dom";

const NoPage = ({ title = "Nie znaleziono strony!"}) => {
    return (
        <div>
            <Navbar/>
            <div className="w-full">
                <div className="max-w-[1240px] mx-auto px-4 flex flex-col items-center justify-center py-[5rem]">
                    <img src={page404} alt="Nie znaleziono strony!" className="w-[400px]"/>
                    <h1 className="text-[28px] font-bold pb-[10px]">{title}</h1>
                    <Link to="/" offset={150}>
                        <button
                            className='hover:scale-105 duration-300 bg-[#932F6D] text-white text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                            Wróć na stronę główną
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NoPage;
