import React from 'react'
import BlogPage from '../../assets/blog/blogpage.png'

const BlogHero = () => {
    return (
        <div className="w-full">
            <div className="max-w-[1240px] mx-auto border-b-[2px] border-[#932F6D]">
                <div className="grid md:grid-cols-2 pt-[5rem] px-4">
                    <div className="flex flex-col justify-center">
                        <h1 className="text-[black] text-[28px]">Zainspiruj się już dziś!</h1>
                        <p className="mulish text-[#2D2D2D]">Zapraszamy do regularnego odwiedzania naszego
                            bloga i śledzenia nowych treści. Chcesz otrzymywać powiadomienia o nowych artykułach? Zapisz
                            się na nasz newsletter!</p>
                        <h1 className="text-[black] text-[18px]">Co znajdziesz na naszym blogu?</h1>
                        <ul className="mulish text-[#2D2D2D] pb-[30px]">
                            <li>Poradniki dla właścicieli sklepów internetowych</li>
                            <li>Trendy w marketingu online</li>
                            <li>Historie sukcesu</li>
                            <li>Nowinki technologiczne</li>
                        </ul>
                    </div>
                    <div className="flex justify-center">
                        <img src={BlogPage} alt="Blog Hero Standing Man" className="w-[400px] h-[400px]"/>
                    </div>
                </div>
                <hr/>
            </div>
        </div>
    )
}

export default BlogHero;