import React from 'react';
import IconWeb from "../../assets/Icon_web.png";
import {FiArrowRight} from "react-icons/fi";
import ScrollLink from "../../hooks/ScrollLink";

const ECommerceHero = () => {


    return (
        <div className="w-full md:pt-[5rem] py-16" id="hero">
            <div className="max-w-[1240px] mx-auto">
                <h1 className="text-[#2D2D2D] text-[38px] text-center">Pakiety cenowe</h1>
                <p className="text-[16px] text-center pt-2 pb-10 px-4">Ceny są poglądowe i w rzeczywistości mogą nie odzwierciedlać realnych cen. Każdy projekt dostosowujemy indywidualnie pod klienta</p>
                <div className="grid md:grid-cols-2 gap-2 md:mx-0 mx-2 pb-10">
                    <div
                        className="flex flex-col justify-center text-center border-[1px] border-[#E5F4F2] pt-[0px] pb-[40px] pr-[30px] pl-[30px] bg-white rounded-[20px]">
                        <img src={IconWeb} alt="icon" className="flex mx-auto h-[83px] w-[83px]"/>
                        <h1 className="text-[22px] font-semibold pt-4 text-[#2D2D2D]">WooCommerce</h1>
                        <h1 className="text-[26px] font-semibold text-[#2D2D2D]">od 6 500 <sup>zł</sup></h1>
                        <p className="mulish text-[16px] pt-4 font-normal">Idealne rozwiązanie dla małych i średnich firm, które szukają funkcjonalnego i prostego w zarządzaniu sklepu internetowego. Dzięki połączeniu systemu WordPress i WooCommerce zyskujesz:</p>
                        <p className="text-[16px] pt-4 font-normal">✔ Intuicyjną obsługę sklepu</p>

                        <p className="text-[16px] pt-4 font-normal">✔ Szeroki wybór wtyczek i rozszerzeń</p>
                        <p className="text-[16px] pt-4 font-normal">✔ Możliwość szybkiego uruchomienia sprzedaży online.</p>

                        <ScrollLink
                            to="#contact" offset={150}>
                            <button
                                className='flex mx-auto hover:scale-105 duration-300 bg-[white] mt-[30px] text-[#932F6D] text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                                Zamów <FiArrowRight/>
                            </button>
                        </ScrollLink>
                    </div>
                    <div
                        className="flex flex-col justify-center text-center border-[1px] border-[#E5F4F2] pt-[20px] pb-[40px] pr-[30px] pl-[30px] bg-[#431532] rounded-[20px] text-white">
                        <img src={IconWeb} alt="icon" className="flex mx-auto h-[83px] w-[83px]"/>
                        <h1 className="text-[22px] font-semibold pt-4">Sklep dedykowany</h1>
                        <h1 className="text-[26px] font-semibold">od 15 000 <sup>zł</sup></h1>
                        <p className="mulish text-[16px] pt-4 font-normal">Rozwiązanie szyte na miarę, stworzone od podstaw z myślą o Twoim biznesie. Dedykowany sklep internetowy to:</p>
                        <p className="text-[16px] pt-4 font-normal">Wsparcie naszych ekspertów</p>
                        <p className="text-[16px] pt-4 font-normal">✔ Pełna personalizacja funkcjonalności</p>
                        <p className="text-[16px] pt-4 font-normal"
                           data-tooltip="Wsparcie w Google Analitics">✔ Wysoka wydajność i skalowalnośćs</p>
                        <p className="text-[16px] pt-4 font-normal">✔ Dopasowanie do indywidualnych potrzeb Twojej branży</p>
                        <ScrollLink to="#contact" offset={150}>
                            <button
                                className='flex mx-auto hover:scale-105 duration-300 mt-[30px] text-[white] text-[16px] font-semibold rounded-[20px] pt-[16px] pr-[50px] pb-[16px] pl-[50px] items-center justify-center'>
                                Zamów <FiArrowRight/>
                            </button>
                        </ScrollLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ECommerceHero;