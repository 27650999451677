import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
// import 'animate.css/animate.min.css';
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import ScrollLink from "../hooks/ScrollLink";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const lista = ["O NAS", "OFERTA", "OPINIE", "PORTFOLIO"];
  const [scrollDown, setScrollDown] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleNavItemClick = () => {
    setNav(false);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleTouchStart = (index) => {
    setHoveredIndex(index);
  };

  const handleTouchEnd = () => {
    setHoveredIndex(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollDown = currentScrollPos > 100;
      setScrollDown(isScrollDown);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={classNames(
        "w-full md:sticky md:top-0 z-[999] bg-[white] fixed min-h-min animate-slide-from-top px-4",
        {
          "bg-transparent": !scrollDown,
          "bg-[#B7BFD8] shadow-lg": scrollDown,
        }
      )}
    >
      <div className="flex items-center h-24 max-w-[1240px] mx-auto text-white">
        <div className="md:w-[20%]">
          <Link to="/home">
            <img className="" src={Logo} alt="Logo HKCode" />
          </Link>
        </div>
        <div className="w-full">
          <div className="hidden md:flex mx-auto glass w-[60%] h-[60px]">
            <ul className="hidden md:flex mx-auto py-0 items-center gap-[40px] text-[#932F6D] font-semibold">
              {lista.map((element, index) => (
                <ScrollLink
                  to={`#${element.toLowerCase().replace(" ", "-")}`}
                  offset={80}
                >
                  <li
                    key={index}
                    className={`flex justify-between items-center hover:scale-105 px-4 cursor-pointer text-[16px] h-[45px] ${
                      index === hoveredIndex ? "text-black" : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onTouchStart={() => handleTouchStart(index)}
                    onTouchEnd={handleTouchEnd}
                    onClick={handleNavItemClick}
                  >
                    {element}
                  </li>
                </ScrollLink>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-[20%] flex justify-end">
          <div onClick={handleNav} className="block md:hidden">
            {nav ? (
              <AiOutlineClose
                size={20}
                color={"black"}
                className="fixed top-10 right-5"
              />
            ) : (
              <AiOutlineMenu size={20} color={"black"} className="fixed top-10 right-5" />
            )}
          </div>
          <div
            className={
              nav
                ? "fixed left-0 top-0 w-[60%] h-full border-r bg-[white] ease-in-out duration-500 z-[999]"
                : "fixed left-[-100%]"
            }
          >
            <Link to="/hero">
              <img className="mx-5 my-10" src={Logo} alt="Logo HKMobile" />
            </Link>
            <ul className="uppercase text-[#932F6D] font-semibold">
              {lista.map((element, index) => (
                <ScrollLink to={`#${element.toLowerCase().replace(" ", "-")}`}>
                  <li
                    key={index}
                    className={`flex justify-between hover:scale-105 items-center px-4 cursor-pointer text-[16px] h-[45px] ${
                      index === hoveredIndex ? "" : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onTouchStart={() => handleTouchStart(index)}
                    onTouchEnd={handleTouchEnd}
                    onClick={handleNavItemClick}
                  >
                    {element}
                  </li>
                </ScrollLink>
              ))}
            </ul>
            <div className="px-2 py-4">
              <ScrollLink to="#contact" offset={50}>
                {" "}
                <button className="md:hidden flex hover:scale-105 duration-300 bg-[#932F6D] text-white text-[16px] rounded-[20px] w-[120px] h-[32px] items-center justify-center">
                  KONTAKT <FiArrowRight />{" "}
                </button>{" "}
              </ScrollLink>
            </div>
          </div>
          <ScrollLink to="#contact" offset={150}>
            <button className="hidden md:flex hover:scale-105 duration-300 bg-[#932F6D] text-white text-[16px] rounded-[20px] w-[136px] h-[42px] items-center justify-center">
              KONTAKT <FiArrowRight />
            </button>
          </ScrollLink>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
