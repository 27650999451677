import React from 'react'
import Business from '../../assets/business.png'

const ECommerceMore = () => {
    return (
        <div className="w-full" id="hero">
            <div className="max-w-[1240px] mx-auto">
                <div className="grid md:grid-cols-2">
                    <div className="flex flex-col justify-center">
                        <h1 className="text-[#2D2D2D] text-[38px]">Dlaczego warto nas wybrać?</h1>
                        <ul className="text-[16px] pt-2 pb-10">
                            <li><strong>Doświadczenie:</strong> Zrealizowaliśmy wiele projektów dla klientów z różnych
                                branż.
                            </li>
                            <li><strong>Wsparcie:</strong> Oferujemy kompleksową pomoc na każdym etapie tworzenia
                                sklepu.
                            </li>
                            <li><strong>Elastyczność:</strong> Dopasowujemy rozwiązania do budżetu i potrzeb klienta.
                            </li>
                        </ul>
                        <h1 className="text-[#2D2D2D] text-[28px]">Zainwestuj w rozwój swojego biznesu online</h1>
                        <p className="text-[16px] pt-2">Niezależnie od tego, czy dopiero zaczynasz swoją przygodę z
                            e-commerce, czy szukasz zaawansowanego rozwiązania dla swojego sklepu, jesteśmy tutaj, aby
                            Ci pomóc. Skontaktuj się z nami, aby otrzymać indywidualną wycenę i rozpocząć
                            współpracę!</p>

                    </div>
                    <div className="flex justify-center items-center">
                        <img src={Business} alt="Business Man"/>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default ECommerceMore;