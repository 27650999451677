import React from 'react';
import WebHero from "../components/webcomponents/WebHero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import WebAdditionals from "../components/webcomponents/WebAdditionals";
import ContactWeb from "../components/webcomponents/ContactWeb";

const OfferWeb = () => {
    return (
        <div>
            <Navbar />
            <WebHero />
            <WebAdditionals />
            <ContactWeb />
            <Footer />
        </div>
    )
}

export default OfferWeb;